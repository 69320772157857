#mainpage {
    display: flex;
}

#mainpage_content {
    flex-grow: 1;
}

#mainpage_poster img {
    width: 300px;
    margin: 0.5em;
}

@media screen and (max-width: 1200px) {
    #mainpage {
        flex-direction: column;
    }
}
